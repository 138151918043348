export const findClassification = (classifications, item) => {
  return classifications.find(
    (c) =>
      c.pageNumber === item.pageNumber &&
      c.tableBoxNumber === item.tableBoxNumber &&
      !c.overriddenByDropin &&
      typeof c.classType === typeof item.classType,
  )
}

export const findClassificationIndex = (classifications, item) => {
  return classifications.findIndex(
    (c) =>
      c.pageNumber === item.pageNumber &&
      c.tableBoxNumber === item.tableBoxNumber &&
      !c.overriddenByDropin &&
      typeof c.classType === typeof item.classType,
  )
}

export const findClassificationToDelete = (classifications, item, tableBoxNumbers) => {
  return classifications.find(
    (classification) =>
      classification.pageNumber === item.pageNumber &&
      !tableBoxNumbers.includes(classification.tableBoxNumber) &&
      typeof classification.classType === typeof item.classType &&
      !classification.overriddenByDropin,
  )
}

export const findClassificationIndexToDelete = (classifications, item, tableBoxNumbers) => {
  return classifications.findIndex(
    (classification) =>
      classification.pageNumber === item.pageNumber &&
      !tableBoxNumbers.includes(classification.tableBoxNumber) &&
      typeof classification.classType === typeof item.classType &&
      !classification.overriddenByDropin,
  )
}

export const isDropinClassification = (box, dropinMetadata) =>
  dropinMetadata.map((metadata) => metadata.pageNumber).includes(box.pageNumber)

export const addFieldsToDropinMetadata = (dropinMetadata) => {
  return dropinMetadata.map((metadata) => {
    metadata.tableBoxAddress = `${metadata.pageNumber}:${metadata.tableNumber}`
    metadata.classType = 'dropins'
    metadata.tableBoxNumber = metadata.tableNumber
    metadata.id = crypto.randomUUID()
    metadata.isDeletableManualClassification = true
    return metadata
  })
}

export const syncClassificationAndDropin = (box, dropinMetadata) => {
  box.overriddenByDropin = true

  const dropin = dropinMetadata.find(
    (dropin) => box.pageNumber === dropin.pageNumber && box.tableBoxNumber === dropin.tableNumber,
  )
  if (dropin) {
    box.main = dropin.main
    box.tableType = dropin.tableType
    box.tableContext = dropin.tableContext
  }
  return box
}

export const findClassificationAndSyncWithDropin = (classifications, dropin) => {
  const originalIndex = classifications.findIndex(
    (c) =>
      c.pageNumber === dropin.pageNumber && c.tableBoxNumber === dropin.tableBoxNumber && c.classType !== 'dropins',
  )
  const original = classifications.find(
    (c) =>
      c.pageNumber === dropin.pageNumber && c.tableBoxNumber === dropin.tableBoxNumber && c.classType !== 'dropins',
  )

  if (original) {
    original.main = dropin.main
    original.tableType = dropin.tableType
    original.tableContext = dropin.tableContext
  }

  return { originalIndex, original }
}

export const sortAndIndexClassifications = (classifications) => {
  return classifications
    .sort((a, b) => {
      return a.pageNumber === b.pageNumber ? a.tableBoxNumber - b.tableBoxNumber : a.pageNumber - b.pageNumber
    })
    .map((classification, index) => {
      classification.index = index
      return classification
    })
}

export const createNewClassification = (overrides) => {
  // Need to update createdBy
  const newClassification = {
    documentId: null,
    classScore: null,
    companyId: null,
    processIdentifier: null,
    createdBy: null,
    createdAt: new Date(),
    comment: null,
    enabled: true,
    active: true,
    hasText: null,
    boxScore: null,
    boxType: null,
    height: null,
    width: null,
    y: null,
    x: null,
    tableBoxAddress: null,
    pageNumber: 'need',
    tableBoxNumber: null,
    tableType: null,
    main: false,
    classType: 'manual',
    tableContext: null,
    uuid: null,
    isNew: true,
    index: null,
    id: crypto.randomUUID(),
  }

  return { ...newClassification, ...overrides }
}

export const sortHTML = (html) => {
  const sortedHTML = html
  const matrixTableTypeOrder = ['IS', 'CF', 'BS', 'DN', 'EB', 'GS', 'KP', 'OT', 'DE', 'GK', 'EG', 'EK']
  sortedHTML.forEach((html, index) => {
    sortedHTML[index].tables = html.tables.sort(
      (a, b) => matrixTableTypeOrder.indexOf(a.sheetType) - matrixTableTypeOrder.indexOf(b.sheetType),
    )
  })
  return sortedHTML
}

export const matrixCellClass = 'matrix-table-cell'
export const matrixHeaderClass = 'matrix-table-header'
export const largeCellClass = 'large-cell'
export const mediumCellClass = 'medium-cell'
export const emptyRequiredCellClass = 'empty-required-cell'
export const currentDocCellClass = 'current-doc-cell'
export const htmlViewCellClass = 'html-view-cell'

export const updateShowContext = (classification, showContext) => {
  let tableContext = classification.tableContext

  if (!tableContext || !tableContext.content) {
    tableContext = showContext ? { tableAddress: classification.tableBoxAddress, action: 'Prepend', content: '' } : null
  }

  let updatedClassification = {
    ...classification,
    tableContext: tableContext,
    showContext: showContext,
  }

  return updatedClassification
}
