import {
  SET_COMPANY_LIST,
  SET_COMPANY_LIST_MAP,
  SET_COMPANY_LIST_FETCHING,
  SET_NEW_ISSUES_LIST,
  ADD_TO_COMPANY_LIST,
  SET_COMPANY_WATCHER,
  CURRENT_COMPANY,
  SET_GICS_LIST,
  SET_CASH_SOURCE_LIST,
  TERMINAL,
  GET_UI_INFO,
  SET_HISTORY_LIST,
  ADD_HISTORY_ENTRY,
  SET_MOST_VIEWED,
  SET_NEXT_REPORTING_LIST,
  SET_LAST_UPDATED_LIST,
  SET_COMPANY_COUNT,
  SET_WATCHING_FLAG,
  SET_SEARCH_FIELD,
  SET_SORTING,
  SET_MEMO_STRUCTURE_LIST,
  SET_COMPANY_LIST_ERROR,
  SET_COMPANY_LIST_PAGE,
  SET_COMPANY_RECENT_DOCUMENTS,
  SET_SEARCH_SUGGESTIONS,
  SET_RAC_FILTER,
} from './mutations'

// Mutations
import { CREATE_WATCHLIST, SET_UNIVERSES, SET_SELECTED_UNIVERSES, SET_USER_PREFERENCES } from '@/stores/User/mutations'

// Libs
import gQLMixin, { handleGraphQLErr } from '@/mixins/graphqlMixin.js'
import Auth from '@aws-amplify/auth'
import { instance } from '@/services/authRequest.js'

// Graph QL
import * as gqlQuery from './queries.graphql'
import * as gqlMutation from './mutations.graphql'
import * as gqlSubscription from './subscriptions.graphql'

import { admin } from '@/services/authRequest.js'

export default {
  toggleTerminal({ commit }, payload) {
    commit(TERMINAL, payload)
  },
  toggleNewIssuesList({ commit }, payload) {
    commit(SET_NEW_ISSUES_LIST, payload || null)
  },
  toggleRACListFilter({ commit }, { type, value }) {
    commit(SET_RAC_FILTER, { type, value })
  },
  setCompanySorting({ commit }, payload) {
    commit(SET_SORTING, payload)
  },
  async getClosestSeenDate({ dispatch }) {
    const lastSeenDate = await dispatch('Notification/getLastSeenNotification', {}, { root: true })

    if (lastSeenDate.length > 0) {
      const timeStampList = lastSeenDate.map((item) => new Date(JSON.parse(item.data).dateTime).getTime())
      return lastSeenDate[timeStampList.indexOf(Math.max(...timeStampList))]
    }

    return null
  },
  async listenCompanyChanges({ commit, dispatch, state }) {
    const session = await Auth.currentSession()
    const currentUserCompanyId = session.idToken.payload['custom:clientId']

    if (!state.companyWatcher) {
      commit(SET_COMPANY_WATCHER)
      const closestSeenDate = await dispatch('getClosestSeenDate')

      if (closestSeenDate) {
        await dispatch('Notification/getMissedNotifications', JSON.parse(closestSeenDate.data).dateTime, { root: true })
      }

      const subscriptionObject = await gQLMixin.methods.subscribeToData(
        gqlSubscription.CompanyNotifications,
        async (res) => {
          if (res?.value?.data?.companyNotifications) {
            const closestSeenDate = await dispatch('getClosestSeenDate')

            dispatch(
              'Notification/newNotification',
              {
                id: closestSeenDate ? closestSeenDate.id : res.value.data.companyNotifications.dateTime,
                data: res.value.data.companyNotifications,
              },
              { root: true },
            )
          }
        },
        { clientId: currentUserCompanyId },
        () => {
          commit(SET_COMPANY_WATCHER, false)
        },
      )

      return subscriptionObject
    }
  },
  async setSearchParam({ commit }, payload) {
    commit(SET_SEARCH_FIELD, payload)
  },
  /**
   * @param { Object } payload
   * @param { Object } payload.filter e.g. { licenseId: Array, favourite: Boolean }
   * @param { Object } payload.nextReportingFilter e.g. { licenseId: Array, retired: Boolean, favourite: Boolean,nextReportingIsInThePast: Boolean }
   */
  async initCompanyList({ commit }, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetInitialCompanyList, payload)
      if (data?.companyList?.data && data.nextReportingList?.data) {
        commit(SET_COMPANY_LIST, data?.companyList?.data)
        commit(SET_COMPANY_COUNT, data?.companyList?.metadata)
        commit(SET_NEXT_REPORTING_LIST, data?.nextReportingList?.data)
        return data
      }
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async getAllCompanyListOrderedByNextReporting(_, payload) {
    payload.limit = 2000
    payload.filters.retired = false
    let companies = {}
    const res = await gQLMixin.methods.communicate(gqlQuery.GetCompanyListOrderedByNextReporting, payload)
    const theActualData = res.data.getCompanyListV2.data
    theActualData.forEach((company, index) => {
      companies[company.name] = {
        ...company,
        sortKey: index,
      }
    })
    return companies
  },
  async getCompanyList({ commit, state }, payload) {
    if (state.companyListError) {
      // Reset the error state on fetch
      commit(SET_COMPANY_LIST_ERROR)
    }

    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyList, payload)
      if (state.companyList.length > 0 && payload.offset > 0) {
        commit(ADD_TO_COMPANY_LIST, data.companyList.data)
      } else {
        commit(SET_COMPANY_LIST_ERROR, data.companyList.error)
        commit(SET_COMPANY_LIST, data.companyList.data ?? [])
        commit(SET_COMPANY_COUNT, data.companyList.metadata ?? {})
      }
      return data
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async getHomeData({ commit, rootGetters }, payload) {
    const apiOpts = {
      limit: null,
      offset: null,
    }

    if (payload && payload?.limit !== null) {
      apiOpts.limit = payload.limit
    }

    if (payload && payload?.offset !== null) {
      apiOpts.offset = payload.offset
    }

    try {
      commit(SET_COMPANY_LIST_FETCHING, true)
      const req = await instance({ prefix: 'user-management' })
      const {
        data: { licenseList, watchList },
      } = await gQLMixin.methods.communicate(gqlQuery.GetHomeData, apiOpts)
      const { data: userData } = await req('/user/preferences')

      commit(`User/${SET_UNIVERSES}`, licenseList, { root: true })

      if (licenseList && !rootGetters['User/watchListOnly']) {
        const initialUniverses = licenseList?.filter((item) => userData.selectedUniverses.indexOf(item.id) > -1 && item)

        commit(`User/${SET_USER_PREFERENCES}`, userData, { root: true })
        commit(`User/${SET_SELECTED_UNIVERSES}`, initialUniverses, { root: true })
      }

      commit(`User/${CREATE_WATCHLIST}`, watchList.data, { root: true })

      return watchList
    } catch (err) {
      handleGraphQLErr(err)
      return err
    } finally {
      commit(SET_COMPANY_LIST_FETCHING, false)
    }
  },
  async getCompanyMap({ commit }) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyListMap, {})
      commit(SET_COMPANY_LIST_MAP, data.getCompanyList)

      return data.getCompanyList
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async getHistoryList({ commit }) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetHistoryList)

      commit(SET_HISTORY_LIST, data.listFlexibleUserStorageCollection.items)
      return data.listFlexibleUserStorageCollection.items
    } catch (err) {
      handleGraphQLErr(err)
    }
  },
  async setHistoryEntry({ commit, state }, payload) {
    if (!payload || !state.currentCompany) {
      return
    }

    const { id, name, ticker } = state.currentCompany
    const getPathDuplicate = payload.path.split(`${id}/`).pop().split('/')

    if (getPathDuplicate.length > 1) {
      return
    }

    try {
      const entry = {
        id,
        name,
        ticker,
        path: payload.path,
      }

      const { data } = await gQLMixin.methods.communicate(gqlMutation.AddHistoryItem, { data: JSON.stringify(entry) })
      if (data.createFlexibleUserStorageItemV2) {
        commit(ADD_HISTORY_ENTRY, data.createFlexibleUserStorageItemV2.data)
      }
    } catch (err) {
      handleGraphQLErr(err)
    }
  },
  /**
   * @param { String } payload The company ID
   */
  watchCompany({ commit }, payload) {
    commit(SET_WATCHING_FLAG, payload)
  },
  /**
   * @param { String } search The keyword
   * @param { Boolean } noLicenseAware if the search should apply to all the licenses available
   */
  async getSearchSuggestions({ state, commit }, payload) {
    const opts = { search: payload?.keyword ?? '', filter: {} }

    if (!state.watchListOnly && !payload.noLicenseAware) {
      opts.filter.licenseId = state.selectedUniverseIds
    }

    if (payload.excludeGICs) {
      opts.filter.excludeGICs = payload.excludeGICs
    }
    if (payload.isTextSearch) {
      opts.textSearchMode = true
    }

    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetSearchSuggestions, opts)
      commit(SET_SEARCH_SUGGESTIONS, data.suggestionList)
      return data.suggestionList
    } catch (err) {
      console.error('[ERROR] Getting search suggestions', err)
      return err.data.suggestionList
    }
  },
  async setSearchSuggestions({ commit }, payload) {
    commit(SET_SEARCH_SUGGESTIONS, payload)
  },
  /**
   * @param { String } payload The search keyword
   */
  async getCompanySuggestions(unused, payload) {
    let endpoint = '/companies?'

    if (payload.batchA) {
      endpoint += `batch_id=${payload.batchA}`
    }

    if (payload.batchB) {
      endpoint += `&batch_id_2=${payload.batchB}`
    }

    if (payload.search) {
      endpoint += `&search=${payload.search}`
    }

    try {
      const request = await admin()
      const response = await request.get(endpoint)

      const { data, error } = response

      if (error) {
        throw new Error(error)
      }

      return data
    } catch (err) {
      console.error('[ERROR] Getting company search suggestions', err)
      return err
    }
  },
  /**
   * @param { String } payload The search keyword
   */
  async getDiffCompanySuggestions(unused, payload) {
    let endpoint = '/diff_companies_list?'

    if (payload.batchA) {
      endpoint += `batch_id_1=${payload.batchA}`
    }

    if (payload.batchB) {
      endpoint += `&batch_id_2=${payload.batchB}`
    }

    if (payload.search) {
      endpoint += `&search=${payload.search}`
    }

    try {
      const request = await admin()
      const response = await request.get(endpoint)

      const { data, error } = response

      if (error) {
        throw new Error(error)
      }

      return data
    } catch (err) {
      console.error('[ERROR] Getting company search suggestions', err)
      return err
    }
  },
  /**
   *
   * @param {*} unused
   * @param {Object} payload can contain `search` or `ticker`
   * @returns list of suggestions or error
   */
  async getAdminCompanySuggestions(unused, payload = {}) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanySuggestions, payload)
      return data?.suggestionList
    } catch (err) {
      console.error('[ERROR] Getting admin company search suggestions', err)
      return err
    }
  },
  async getInstrumentChart(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetInstrumentChart, payload ? payload : {})
      return data.getInstrumentGraph
    } catch (err) {
      console.error(err)
      return err.data.getInstrumentGraph
    }
  },
  async getInstrumentsTimeseries(unused, payload) {
    if (!payload) {
      return []
    }

    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetInstrumentsTimeseries, payload ? payload : {})
      return data?.getInstrumentsTimeseries
    } catch (err) {
      console.error('[ERROR] Fetching time series', err)
      return err.data.getInstrumentsTimeseries
    }
  },
  async getCompaniesTimeseries(unused, payload) {
    if (!payload?.tag) {
      return {}
    }

    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompaniesTimeseries, payload ? payload : {})
      return data?.getCompaniesTimeseries
    } catch (err) {
      console.error('[ERROR] Fetching time series', err)
      return err.data.getCompaniesTimeseries
    }
  },
  async getMostViewedCompanies({ commit, rootGetters }, limit = 5) {
    try {
      let licenses
      if (rootGetters['User/watchListOnly']) {
        licenses = rootGetters['User/licenseList'].map((item) => item.id)
      } else {
        licenses = rootGetters['User/selectedUniverseIds']
      }
      const filter = {
        limit,
        licenseIds: licenses,
      }
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetMostViewedCompanies, { filter })
      commit(SET_MOST_VIEWED, data.getMostViewedCompanies.data)
      return data.getMostViewedCompanies.data
    } catch (err) {
      handleGraphQLErr(err)
    }
  },
  async getNextReportingList({ commit, rootGetters }, limit = 5) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyListOrderedByNextReporting, {
        limit,
        filter: {
          licenseId: rootGetters['User/selectedUniverseIds'],
          retired: false,
          nextReportingIsInThePast: false,
          favourite: rootGetters['User/watchListOnly'],
          nextReportingMode: true,
        },
      })
      commit(SET_NEXT_REPORTING_LIST, data.getCompanyListV2.data)
      return data.getCompanyListV2.data
    } catch (err) {
      handleGraphQLErr(err)
    }
  },

  async getLastUpdatedCompanies({ commit, rootGetters }, limit = 5) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetLastUpdatedCompanies, {
        limit,
        filter: {
          licenseId: rootGetters['User/selectedUniverseIds'] || [],
          months: 2,
        },
      })

      commit(SET_LAST_UPDATED_LIST, data.lastUpdatedList.data)
      return data
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  /**
   * Get detailed data of the passed company and populate
   * the currentCompany value within the Company State
   * @param { Object } payload - the ID of the company e.g. { id: 'Adient' }
   */
  async getCompanyDetail({ commit }, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyDetail, payload)
      if (!data?.companyDetails) {
        throw new Error('The companyDetails object is missing from the returned data')
      }

      commit(CURRENT_COMPANY, data?.companyDetails?.company)
      return data.companyDetails
    } catch (err) {
      console.error('[ERROR] - The detailed data about the company did return something wrong', err.message)
      return err
    }
  },
  async getIndustryPeers(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyComparables, { id: payload })

      if (!data) {
        throw new Error('[ERROR] - Unable to get the company comparables data')
      }

      return data.getCompanyCompsList
    } catch (err) {
      console.error(err.message)
      return err
    }
  },
  async getCompanyInstruments(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyInstruments, payload)

      if (!data) {
        throw new Error('[ERROR] - Unable to get the instruments data')
      }

      return data?.getCompany?.company
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async getMemoFinancials(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyMemoDatapoints, payload)
      return data?.getCompanyMemoDatapoints
    } catch (err) {
      return new Error(err)
    }
  },
  async getMemoStructure({ commit }, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyMemoStructure, payload)

      if (!data) {
        throw new Error('[ERROR] - Unable to get the structure data')
      }

      commit(SET_MEMO_STRUCTURE_LIST, data?.getCompanyMemoStructure?.companyMemoStructure)
      return data
    } catch (err) {
      console.error(err)
      return err
    }
  },
  /**
   * This is using the old API
   * It will eventually replaced by AppSync
   * @param { String } datapointId
   */
  async getDebugDatapoints(unused, datapointId) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetDebugDatapoint, { dpId: datapointId })

      if (!data) {
        throw new Error('[ERROR] - Unable to get the debug information')
      }
      return data
    } catch (err) {
      return err
    }
  },
  /**
   * @param { String } datapointId
   */
  async getDatapointsInfo(unused, datapointId) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetDatapointsInfo, { dpIds: [datapointId] })

      if (!data) {
        throw new Error('[ERROR] - Unable to get the debug information')
      }
      return data
    } catch (err) {
      return err
    }
  },
  /**
   * @param { Object } payload { batchId, batchType}
   */
  async getCompanyDatapointsByBatch(unused, payload) {
    const { batchId, batchType, companyId, subset } = payload
    try {
      const res =
        batchType && batchType === 'ondemand'
          ? await gQLMixin.methods.communicate(gqlQuery.GetBatchMapLink, { batchId })
          : await gQLMixin.methods.communicate(gqlQuery.GetCompanyDataMapLink, { companyId, subset, batchId })

      if (!res) {
        throw new Error('[ERROR] - Unable to get the data map')
      }

      return res
    } catch (err) {
      return err
    }
  },

  /**
   *
   * @param { Object } payload - An Object containing the URL e.g. { url: https://mydomain.com/files/file.json }
   */
  async getCompanyDataGrid(unused, payload) {
    try {
      const req = await instance()
      const { data } = await req.get(payload.url)

      if (!data) {
        throw new Error('[ERROR] - Unable to get the data grid')
      }

      return data
    } catch (err) {
      return err
    }
  },
  async getCompanyMemo(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyMemo, payload)
      return data?.getCompany?.company
    } catch (err) {
      console.error('[ERROR] Fetching the company memo', err)
      return err
    }
  },
  async getCompanyForecastingParams(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyForecastingParams, payload)
      return data?.getCompany?.company?.forecasting
    } catch (err) {
      console.error('[ERROR] Fetching the forecasting parameters', err)
      return err
    }
  },
  wipeCompanyDetail({ commit }) {
    commit(CURRENT_COMPANY, null)
  },
  async getCompanyComps(unused, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyCompsList, payload)
      return data.getCompanyMemoCompsDatapoints
    } catch (err) {
      return err
    }
  },
  async getCashSourceList({ commit }) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCashSourceList, {})
      commit(SET_CASH_SOURCE_LIST, data.getCashSourceList)
      return data.getCashSourceList
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async getGicsList({ commit }) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetIndustryList, {})
      commit(SET_GICS_LIST, data.getIndustryList)
      return data.getIndustryList
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async getUiInfo({ commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlQuery.GetUiInfo, payload)
      const pageInfo = {
        descriptions: res.data.getUiInfo.descriptions ?? [],
        options: res.data.getUiInfo.options ?? [],
      }
      commit(GET_UI_INFO, { data: pageInfo, area: payload.page })
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async setCompanyListPage({ commit }, payload) {
    commit(SET_COMPANY_LIST_PAGE, payload)
  },

  async getCompanyRecentDocuments({ commit }, payload) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlQuery.GetCompanyRecentlyAddedDocuments, {
        id: payload,
        months: 6,
      })
      const { recentDocuments } = data
      if (recentDocuments.error) {
        throw new Error(recentDocuments.error)
      } else {
        commit(SET_COMPANY_RECENT_DOCUMENTS, recentDocuments.data)
        return recentDocuments.data
      }
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
}
