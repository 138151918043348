import GC from '@grapecity/spread-sheets'
import { useRoute } from 'vue-router'
import { createNamespacedHelpers } from 'vuex-composition-helpers/dist'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import eventBus from '@/utils/eventBus'
import getWorkBook from '@/spreadsheets/getWorkBook'
import { relativeLink } from '@/utils/links.js'
import { isMacintosh, metaKeyEventCode } from '@/utils/device'
import getUndoOperationRanges from '@/spreadsheets/utils/getUndoOperationRanges'
import { useCustomEventTracking } from '@/composables/eventTracking'

import { matrixCellClass, matrixHeaderClass } from '@/components/admin/documents/editor/utils.js'

export default function keyboardEvents() {
  const route = useRoute()
  const { useGetters: useUserGetters } = createNamespacedHelpers('User')
  const { user, isAdmin } = useUserGetters(['user', 'isAdmin'])
  const { useGetters: useModalGetters, useActions: useModalActions } = createNamespacedHelpers('Modal')
  const { modals } = useModalGetters(['modals'])
  const { openModal, closeModal } = useModalActions(['openModal', 'closeModal'])
  const { useGetters: useAppGetters, useActions: useAppActions } = createNamespacedHelpers('App')
  const { visibleTerminal } = useAppGetters(['visibleTerminal'])
  const { toggleTerminal } = useAppActions(['toggleTerminal'])
  const { useActions: useSpreadsheetGetters } = createNamespacedHelpers('Spreadsheet')
  const { toggleSearchRangePanel } = useSpreadsheetGetters(['toggleSearchRangePanel'])

  const { useGetters: useModelsGetters } = createNamespacedHelpers('Models')
  const { availableSaving, loadedModel, hasUpdates, isSaving } = useModelsGetters([
    'availableSaving',
    'loadedModel',
    'hasUpdates',
    'isSaving',
  ])

  const { useGetters: useDocumentEditorGetters, useActions: useDocumentEditorActions } =
    createNamespacedHelpers('DocumentEditor')

  const { currentPage, scrollMode, pageCount, subsetPages, currentSubset } = useDocumentEditorGetters([
    'currentPage',
    'scrollMode',
    'pageCount',
    'subsetPages',
    'currentSubset',
  ])
  const { setCurrentPage, setScrollToPageNumber } = useDocumentEditorActions([
    'setCurrentPage',
    'setScrollToPageNumber',
  ])

  const supportKey = ref('altKey')
  const metaKeyDown = ref(false)
  const dropdownUid = ref(undefined)

  onMounted(() => {
    addKeyShortCuts()
  })

  const addKeyShortCuts = () => {
    window.addEventListener('keyup', keyUpHandler)
    window.addEventListener('keydown', keyDownHandler, true)
  }

  onUnmounted(() => {
    unloadKeyShortcuts()
  })

  const unloadKeyShortcuts = () => {
    window.removeEventListener('keydown', keyDownHandler)
    window.removeEventListener('keyup', keyUpHandler)
  }

  const addCompanyShortcuts = (e) => {
    if (!isAdmin.value) {
      return
    }

    let shortcutLink = ''
    // Alt
    if (e[supportKey.value]) {
      switch (e.code) {
        case 'Digit0':
          shortcutLink = `/admin/companies/${route.params.company}/edit#columnselection`
          break
        case 'Digit1':
          shortcutLink = `/admin/companies/${route.params.company}/edit#description`
          break
        case 'Digit2':
          shortcutLink = `/admin/companies/${route.params.company}/edit#rowcollapsing`
          break
        case 'Digit3':
          shortcutLink = `/admin/companies/${route.params.company}/edit#formulaexclusion`
          break
        case 'Digit4':
          shortcutLink = `/admin/companies/${route.params.company}/edit#tags`
          break
        case 'Digit5':
          shortcutLink = `/admin/companies/${route.params.company}/edit#dataeditor`
          break
        case 'Digit6':
          shortcutLink = `/admin/documents#${route.params.company}-`
          break
        case 'Digit7':
          shortcutLink = `/admin/companies/distribution#${route.params.company}`
          break
        case 'Digit8':
          shortcutLink = `/admin/companies/${route.params.company}/edit#tickers`
          break
        case 'Digit9':
          // only in financials or forecasting
          if (route.meta.title.match(/Financials|Forecasting/)) {
            openModal({ name: 'jsonUploader' })
          }
          break
        case 'Equal':
          shortcutLink = `/admin/companies/${route.params.company}/edit#flattening`
          break
        case 'Minus':
          shortcutLink = `/admin/companies/${route.params.company}/edit#reporting`
          break
      }
      if (shortcutLink) {
        window.open(relativeLink(shortcutLink))
      }
    }
  }

  const addTerminalShortcut = (e) => {
    if (e.code === 'KeyT' && e[supportKey.value]) {
      e.preventDefault()
      toggleTerminal(!visibleTerminal.value)
      return
    }
  }

  const addModelShortcuts = (e) => {
    if (e.code === 'KeyX' && e[supportKey.value]) {
      eventBus.$emit('cc:company:togglefilters')
      return e.preventDefault()
    }

    if (e.code === 'KeyA' && e[supportKey.value]) {
      eventBus.$emit('cc:company:toggleaudit')
      return e.preventDefault()
    }

    if (e.code == 'KeyY' && e[supportKey.value]) {
      displayUpdateDialog()
      return e.preventDefault()
    }

    if (e.code == 'KeyC' && e[supportKey.value]) {
      eventBus.$emit('cc:company:togglecharts')
      return e.preventDefault()
    }
    if (e.key === metaKeyEventCode()) {
      metaKeyDown.value = false
    }
    return e
  }

  const hasUpdateModal = computed(() => {
    return modals.value.find((item) => item.name === 'updateModel')
  })

  const displayUpdateDialog = () => {
    if (!hasUpdateModal.value || availableSaving.value || hasUpdates.value?.periods?.length < 1 || !loadedModel.value) {
      return
    }

    openModal({ name: 'updateModel' })
  }

  const addSaveModelHandling = (e) => {
    // Cmd + S – this needs to be a separate keydown handler because
    // Chrome does not trigger `keyup` event for `Cmd+S`
    if (e.key === metaKeyEventCode()) {
      metaKeyDown.value = true
    }
    if (e.code === 'KeyS' && metaKeyDown.value) {
      e.preventDefault()
      if (!isSaving.value) {
        if (e.shiftKey) {
          eventBus.$emit('cc:company:model:saveas')
          useCustomEventTracking('save_model_as', 'company_model', {
            action_origin: 'kbd_shortcut',
          })
        } else {
          eventBus.$emit('cc:company:model:save')
          useCustomEventTracking('save_model', 'company_model', {
            action_origin: 'kbd_shortcut',
          })
        }
      }
      metaKeyDown.value = false
    }
  }

  const addSaveNoteHandling = (e) => {
    // Cmd + S – this needs to be a separate keydown handler because
    // Chrome does not trigger `keyup` event for `Cmd+S`
    if (e.key === metaKeyEventCode()) {
      metaKeyDown.value = true
    }
    if (e.code === 'KeyS' && metaKeyDown.value) {
      e.preventDefault()
      eventBus.$emit('cc:company:notes:saveas')
      metaKeyDown.value = false
    }
  }

  const addComparablesShortcuts = (e) => {
    if (e.code === 'KeyX' && e[supportKey.value]) {
      eventBus.$emit('cc:spreadsheet:comps:togglefilters')
      return e.preventDefault()
    }

    if (e.code == 'KeyA' && e[supportKey.value]) {
      eventBus.$emit('cc:spreadsheet:comps:toggleaudit')
      return e.preventDefault()
    }

    if (e.code == 'KeyC' && e[supportKey.value]) {
      eventBus.$emit('cc:spreadsheet:comps:togglecharts')
      return e.preventDefault()
    }

    if (e.code == 'Digit9' && e[supportKey.value] && isAdmin) {
      // ALT + 9
      openModal({ name: 'jsonUploader' })
    }
  }

  const disablePageBackArrow = computed(() => {
    if (subsetPages.value) {
      return currentPage.value === currentSubset.value[0]
    } else {
      return currentPage.value === 1
    }
  })

  const disablePageForwardArrow = computed(() => {
    if (subsetPages.value) {
      const lastSubsetValue = currentSubset.value[currentSubset.value.length - 1]
      if (scrollMode.value === '2page') {
        return (
          currentPage.value === lastSubsetValue ||
          currentPage.value === currentSubset.value[currentSubset.value.length - 2]
        )
      } else {
        return currentPage.value === lastSubsetValue
      }
    } else {
      return scrollMode.value === '2page'
        ? currentPage.value === pageCount.value || currentPage.value + 1 === pageCount.value
        : currentPage.value === pageCount.value
    }
  })

  const addPdfNavigation = (e) => {
    if (!currentPage.value || document.activeElement.tagName !== 'BODY') {
      return
    }

    if (e.code === 'ArrowLeft' && !disablePageBackArrow.value) {
      handlePageIncrement(scrollMode.value === '2page' ? -2 : -1)
    }

    if (e.code === 'ArrowRight' && !disablePageForwardArrow.value) {
      handlePageIncrement(scrollMode.value === '2page' ? 2 : 1)
    }
  }

  const handlePageIncrement = (pageIncrement) => {
    if (scrollMode.value === 'continuous') {
      setCurrentPage(currentPage.value + pageIncrement)
      setScrollToPageNumber(currentPage.value + pageIncrement)
    } else if (subsetPages.value) {
      const currentPageIndex = currentSubset.value.findIndex((subsetPage) => subsetPage === currentPage.value)
      setCurrentPage(currentSubset.value[currentPageIndex + pageIncrement])
    } else {
      const page = currentPage.value + pageIncrement > 0 ? currentPage.value + pageIncrement : 1
      setCurrentPage(page)
    }
  }

  /**
   * The undo handling triggers when pressing CMD/CTRL+Z or Y
   * while the user cursor doesn't have the focus on the Workbook canvas
   * so it's mainly used for handling undo/redo actions when clicking on the
   * WorkBook toolbar
   */
  const addUndoHandling = (e) => {
    // Notebook modals can be open on the spreadsheet page.
    // If the notebook is focused, we don't want to trigger undo/redo for the workbook
    const isNotebook = notebookIsFocused()
    if (isNotebook) {
      return
    }

    const workBook = getWorkBook()
    if (!workBook) {
      return
    }

    const composedKey = isMacintosh() ? 'metaKey' : 'ctrlKey'
    const isWithinSheet = !!e.composedPath().find((node) => node?.classList?.contains('sjs-sheet'))
    const isWithinInput = e.target.tagName === 'INPUT'

    if (isWithinSheet || isWithinInput) {
      return
    }

    if (!isWithinSheet && e.code === 'KeyZ' && e[composedKey] && !e.shiftKey) {
      if (workBook.undoManager().canUndo()) {
        const undoStack = workBook.undoManager().getUndoStack()
        const undoOperation = undoStack[undoStack.length - 1]
        const undoRanges = getUndoOperationRanges(undoOperation)
        workBook.undoManager().undo()
        undoRanges.forEach((range) => eventBus.$emit('cc:sheet:cell:undo', range))
      }
      e.preventDefault()
      return
    }

    if (
      (!isWithinSheet && e.code === 'KeyZ' && e[composedKey] && e.shiftKey) ||
      (!isWithinSheet && e.code === 'KeyY' && e[composedKey])
    ) {
      if (workBook.undoManager().canRedo()) {
        const redoStack = workBook.undoManager().getRedoStack()
        const redoOperation = redoStack[redoStack.length - 1]
        const redoRanges = getUndoOperationRanges(redoOperation)
        workBook.undoManager().redo()
        redoRanges.forEach((range) => eventBus.$emit('cc:sheet:cell:redo', range))
      }
      e.preventDefault()
      return
    }
  }

  const addBoxInteractions = (e) => {
    if (e.code !== 'Escape') {
      return
    }
    eventBus.$emit('cc:boxes:discard')
    return e.preventDefault()
  }

  const addMatrixInteractions = (e) => {
    const targetIsMatrixCell = e.target.classList.contains(matrixCellClass)
    const targetIsMatrixHeader = e.target.classList.contains(matrixHeaderClass)
    const rowLength = Number(e.target.dataset['rowLength'])
    if (targetIsMatrixCell || targetIsMatrixHeader) {
      const focusableElements = document.querySelectorAll(`.${matrixCellClass}, .${matrixHeaderClass}`)
      var index = Array.from(focusableElements).indexOf(document.activeElement)
      if (e.code === 'ArrowLeft' && index > 0) {
        const previousElement = focusableElements[index - 1]
        previousElement.focus()
        return e.preventDefault
      } else if (e.code === 'ArrowRight' && index < focusableElements.length - 1) {
        const nextElement = focusableElements[index + 1]
        nextElement.focus()
        return e.preventDefault
      } else if (e.code === 'ArrowDown' && index < focusableElements.length - rowLength) {
        const nextRowElement = focusableElements[index + rowLength]
        nextRowElement.focus()
        return e.preventDefault
      } else if (e.code === 'ArrowUp' && index >= rowLength) {
        const previousRowElement = focusableElements[index - rowLength]
        previousRowElement.focus()
        return e.preventDefault
      } else if (e.code === 'Enter') {
        const documentIds = e.target.dataset['documentIds'].split(',')
        const pages = e.target.dataset['pages'].split(',')
        eventBus.$emit('cc:matrix:select', { documentIds, pages })
        return e.preventDefault
      }
    }
  }

  const addMatrixKeyDownInteractions = (e) => {
    const targetIsMatrixCell = e.target.classList.contains(matrixCellClass)
    const targetIsMatrixHeader = e.target.classList.contains(matrixHeaderClass)
    if (targetIsMatrixCell || targetIsMatrixHeader) {
      if (e.code === 'ArrowLeft' || e.code === 'ArrowRight' || e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        e.preventDefault()
      }
    }
  }

  const handleDropdownArrow = (incrementIndex) => {
    const focusableElements = document.querySelectorAll(
      'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])',
    )
    var index = Array.from(focusableElements).indexOf(document.activeElement)
    const dropdownContent = document.getElementById(`dropdown-content-${dropdownUid.value}`)
    if (index > -1) {
      var nextElement = focusableElements[index + incrementIndex] || focusableElements[0]
      nextElement.focus()
      if (dropdownContent && !dropdownContent.contains(nextElement)) {
        eventBus.$emit('cc:close:dropdown')
      }
    }
  }

  const addDropdownInteractions = (e) => {
    if (e.code === 'ArrowDown') {
      handleDropdownArrow(1)
    } else if (e.code === 'ArrowUp') {
      handleDropdownArrow(-1)
    } else if (e.code === 'Escape') {
      eventBus.$emit('cc:close:dropdown')
      document.getElementById(`dropdown-button-${dropdownUid.value}`).focus()
      dropdownUid.value = undefined
    }
  }

  const addDropdownHandling = (e) => {
    if (e.target.id && e.target.id.includes('dropdown-button')) {
      dropdownUid.value = e.target.id.replace('dropdown-button-', '')
      addDropdownInteractions(e)
    }

    if (dropdownUid.value) {
      const dropdownContent = document.getElementById(`dropdown-content-${dropdownUid.value}`)

      if (dropdownContent && dropdownContent.contains(e.target)) {
        addDropdownInteractions(e)
      } else if (dropdownContent && !dropdownContent.contains(e.target) && e.code === 'Tab') {
        // if the focus is outside the dropdown, close it
        eventBus.$emit('cc:close:dropdown')
        dropdownUid.value = undefined
      }
    }
  }

  const closeComment = (e) => {
    const workBook = getWorkBook()
    if (workBook) {
      const sheet = workBook.getActiveSheet()
      const rowIndex = sheet.getActiveRowIndex()
      const colIndex = sheet.getActiveColumnIndex()
      const comment = sheet.getCell(rowIndex, colIndex).comment()
      if (comment) {
        // hide comments
        comment.commentState(GC.Spread.Sheets.Comments.CommentState.normal)
        sheet.setActiveCell(rowIndex, colIndex)
        // Comments are shown on hover, so we have to simulate a mousemove event :(
        document.getElementsByTagName('canvas')[0].dispatchEvent(new Event('mousemove'))
      }
      // hide search range panel and focus the spreadsheet
      toggleSearchRangePanel(false)
      if (e.target.classList.contains('cc-sjs-cell-editor')) {
        workBook.focus()
      }
    }
  }

  const closeTerminal = () => {
    toggleTerminal(false)
  }

  const closeDropdown = () => {
    eventBus.$emit('cc:close:dropdown')
  }

  const resetForecastItem = () => {
    eventBus.$emit('forecast:model:delete', null)
  }

  const closeModals = (e) => {
    const ignoredModalNames = ['notesModal', 'editNoteModal', 'addNewCompany', 'auditModal']
    const modalsToClose = modals.value.filter(
      (modal) => !ignoredModalNames.includes(modal.name) && !modal.name.includes('chartExpanded'),
    )
    modalsToClose.forEach((modal) => closeModal({ name: modal.name }))
    eventBus.$emit('cc:handle:close:modal', null)

    const chartIsExpanded = modals.value.find((modal) => modal.name.includes('chartExpanded') && modal.visible)
    const editingSpreadsheet = e.composedPath().find((node) => node?.classList?.contains('cc-sjs-cell-editor--editing'))
    if (!chartIsExpanded && !editingSpreadsheet) {
      closeDropdown()
    }
  }

  const addEscapeHandling = (e) => {
    if (e.code === 'Escape') {
      closeComment(e)
      closeTerminal()
      closeModals(e)
      resetForecastItem()
    }
  }

  const keyUpHandler = (e) => {
    addDropdownHandling(e)
    addEscapeHandling(e)

    if (user) {
      addTerminalShortcut(e)
    }
    if (e.key === metaKeyEventCode() && metaKeyDown.value) {
      metaKeyDown.value = false
    }

    switch (route.meta.title) {
      case 'Financials':
      case 'Custom Financials':
      case 'Forecasting':
        addModelShortcuts(e)
        break
      case 'Company Comparables':
      case 'Sector Comparables':
      case 'Market Comparables':
        addComparablesShortcuts(e)
        break
      case 'Document Editor':
        addPdfNavigation(e)
        addBoxInteractions(e)
        addMatrixInteractions(e)
        break
    }

    if (isAdmin.value && route.params.company) {
      addCompanyShortcuts(e)
    }
  }

  const addSpreadsheetShortcuts = (e) => {
    const composedKey = isMacintosh() ? 'metaKey' : 'ctrlKey'
    if (e.code === 'Escape') {
      toggleSearchRangePanel(false)
    }
    // Ctrl/Command + /
    if (e.code === 'Slash' && e[composedKey]) {
      openModal({ name: 'spreadsheetShortcutModal' })
    }
  }

  const addCompanyLinkNavigation = (e) => {
    if (e.target.id.includes('companyLink-')) {
      var index = Number(e.target.id.split('-')[1])
      if (e.code === 'ArrowDown') {
        document.getElementById(`companyLink-${index + 1}`).focus()
      } else if (e.code === 'ArrowUp' && index > 0) {
        document.getElementById(`companyLink-${index - 1}`).focus()
      }
    }
  }

  const keyDownHandler = (e) => {
    if (e.key === metaKeyEventCode()) {
      metaKeyDown.value = true
    }

    if (route.hash && route.hash.includes('reporting')) {
      if (e.code == 'KeyC' && metaKeyDown.value) {
        eventBus.$emit('cc:reporting:copy:comment')
      }
    }

    if (
      route.meta.title === 'Financials' ||
      route.meta.title.includes('Forecasting') ||
      route.meta.title.includes('Comparables')
    ) {
      addSpreadsheetShortcuts(e)
    }

    if (route.meta.title === 'Document Editor') {
      addMatrixKeyDownInteractions(e)
    }

    addCompanyLinkNavigation(e)
    const includeSaveModel = route.meta.title === 'Forecasting' || route.meta.title === 'Financials'
    if (notebookIsFocused()) {
      addSaveNoteHandling(e)
    } else if (includeSaveModel) {
      addSaveModelHandling(e)
    }
    addUndoHandling(e)
  }

  const notebookIsFocused = () => {
    const elem = document.activeElement
    const isNoteBody = elem.className.includes('tiptap')
    const isNoteTitle = elem.placeholder === 'Note title'
    return isNoteBody || isNoteTitle
  }
}
