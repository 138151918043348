export default {
  companyList: [],
  companyListPage: 1,
  companyListFetching: false,
  companyListError: null,
  companySorting: {
    name: 'ASC',
  },
  racFilter: {
    green: null,
    yellow: null,
    red: null,
  },
  companyMap: [],
  currentCompany: null,
  companyWatcher: false,
  gicsList: [],
  cashSourceList: [],
  terminalNavigationVisible: false,
  newIssuesOnly: null,
  filter: null,
  companyUiInfo: {},
  historyList: [],
  mostViewedList: [],
  nextReportingList: [],
  lastReportedList: [],
  companyListCount: {},
  searchParam: null,
  memoStructureList: [],
  recentDocuments: [],
  searchSuggestions: [],
}
