import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

// Plugins
import ui from '@cognitivecredit/ui-core'
import VueGtag from 'vue-gtag'
import InlineSvg from 'vue-inline-svg'
import resizeDirective from '@/utils/directives/resize'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import config from '@/config'

// Register global routing
import router from './router/'

// AWS authentication
import Amplify from '@aws-amplify/core'
import awsconfig from '@/auth/aws-exports'

// Store
import store from '@/stores/'
import eventBus from '@/utils/eventBus'

const app = createApp(App)
const pinia = createPinia()
app.use(store)
app.use(router)
app.use(pinia)
// Register special UI components (e.g. CognitiveModal)
Object.values(ui.components).forEach((component) => {
  component.compatConfig = { MODE: 3 }
})
Object.values(ui.plugins).forEach((name) => {
  app.use(name, { store })
})

app.component('InlineSvg', InlineSvg)
app.use(VueTelInput)
app.use(VueVirtualScroller)
app.provide('eventBus', eventBus)
app.directive('resize', resizeDirective)
app.component('VueDatePicker', VueDatePicker)

if (!window.Cypress && config.STAGE === 'app') {
  app.use(
    VueGtag,
    {
      bootstrap: false,
      config: { id: config.GOOGLE_ANALYTICS_UA },
    },
    router,
  )
}

Amplify.configure(awsconfig)

/**
 * Initialisation
 */
window.CC = window.CC ?? {
  oldDependentCells: [],
  oldPrecedentCells: [],
}

/*eslint no-unused-vars: "off"*/
app.mount(document.body)
